import logo from '../../assets/images/logo.jpg'

const BusinessInfo = {
    name: "DAUDC Pos",
    fullName: "DR. ALFAZ UDDIN DIAGNOSTIC CENTRE",
    dealer: "(X-ray, ECG, Medicine and all other medical services)",
    address: "Munshi Market, MC Bazar, Sreepur, Gazipur",
    mobile: "Mobile: 01790957518",
    img: logo,
}

export default BusinessInfo;
