import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import profitimg from '../../../../src/assets/images/profit.png'
import income from '../../../../src/assets/images/income.png'
import useFetchData from '../../../hooks/useFetchData';

const ThisMonthIncomes = () => {
    const { items: incomes } = useFetchData("https://servers.expressmartbd.com/daudc/getthismonthincomes");
    const TotalIncomes = incomes?.reduce((total, currentValue) => total + parseInt(currentValue?.amount), 0);


    return (
        <div class="mx-auto w-11/12 bg-coral text-black rounded-lg overflow-hidden bg-[#E7FCE9] shadow-lg glassmorphism p-5">
            <div className='flex justify-between items-center'>
                <div class="flex flex-col justify-center items-center">
                    <div class="text-sm font-semibold mb-2">This Month Incomes</div>
                    <p class="text-xl text-black">৳ {(TotalIncomes).toFixed(2)}</p>
                </div>
                <div class="">
                    <img src={income} alt="Card image" class="mx-auto my-auto h-20 w-20 rounded-full border-4 border-red-600 object-cover" />
                </div>
            </div>
        </div>
    );
};

export default ThisMonthIncomes;