import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../../Shared/Loader';


const UpdateModal = ({ updateBankDeposit, setUpdateBankDeposit,fetchData }) => {
    const userAc = useSelector((state) => state.user.user);
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const { _id, date, accountno, name, invoice, amount, purpose, status } = updateBankDeposit;
    const [isBankAccountSearching, setIsBankAccountSearching] = useState(false);
    const [bankAccountText, setBankAccountText] = useState([]);
    const [bankAccountSearch, setBankAccountSearch] = useState([]);
    const [currentBankAccount, setCurrentBankAccount] = useState([]);
    const [isSelectBankAccount, setIsSelectBankAccount] = useState(false);

    useEffect(() => {
        fetch('https://servers.expressmartbd.com/daudc/getbankaccounts')
            .then(res => res.json())
            .then(data => {
                setAccounts(data);
                setLoading(false)
            })
    }, [])

    const onSubmit = (data) => {
        const name = currentBankAccount?.name;
        const accountno = currentBankAccount?.accountno;
        const bankdeposit = parseInt(data.amount);
        const user = userAc?.email.split('@')[0];
        const approvedBy = false;
        const newData = { ...data, name, accountno, bankdeposit, user, approvedBy }
        fetch(`https://servers.expressmartbd.com/daudc/getbankdeposit/${_id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(newData)
        })
            .then(res => res.json())
            .then(data => {
                setUpdateBankDeposit(null);
                toast('Bank Deposit Upadated Successful');
                fetchData();
            })
    }

    const noEdit = () => {
        window.location.reload(false)
    }

    if (loading) {
        return <Loader />
    }

    const handleSearchBankAccount = (event) => {
        setBankAccountText(event.target.value)
        const match = accounts.filter(bankAccount => bankAccount.name.toLowerCase().includes(bankAccountText)
            || bankAccount.name.includes(bankAccountText) || bankAccount.name.toUpperCase().includes(bankAccountText));
        setBankAccountSearch(match);
        setIsBankAccountSearching(true);
    }

    const handleSelectBankAccount = (bankAccount, event) => {
        event.preventDefault();
        event.stopPropagation();
        const opening = parseFloat(bankAccount?.opening)
        const url = `https://servers.expressmartbd.com/daudc/filteredaccount?accountno=${bankAccount.accountno}`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                const balance = opening + data?.defaultTotalDeposit - data?.defaultTotalWithdraw;
                setCurrentBankAccount({ ...bankAccount, balance });
                setIsSelectBankAccount(true)
            })
        setIsBankAccountSearching(false);
        setBankAccountText('');
    }

    return (
        <div>
            <input type="checkbox" id="update-modal" class="modal-toggle" />
            <div class="modal mt-12">
                <div class="modal-box relative text-xs">
                    <label for="update-modal" onClick={() => noEdit()} class="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <h3 class="text-lg font-bold">Update-{accountno}. {name}</h3>
                    <div class="form-control w-full max-w-xs mt-5 col-span-2 mx-auto">
                        <div class="form-control w-full">
                            <input onChange={handleSearchBankAccount} value={bankAccountText} type="text" placeholder="Search Bank Account" class="rounded-lg bg-red-300 p-3 placeholder-black  text-center  focus:border-blue-500 focus:outline-none w-full max-w-xs" />
                        </div>
                        <div style={isBankAccountSearching ? {} : { visibility: 'hidden' }} className={`grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 bg-gray-200 p-2 ${isBankAccountSearching ? 'h-16' : "h-8"} w-full overflow-auto`}>
                            {
                                isBankAccountSearching ? bankAccountSearch.map(bankAccount =>
                                    <label for="update-modal" onClick={event => handleSelectBankAccount(bankAccount, event)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 p-3 text-white text-xs shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{bankAccount.code}. {bankAccount?.name}</label>) :
                                    accounts.map(bankAccount =>
                                        <label for="update-modal" onClick={event => handleSelectBankAccount(bankAccount, event)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 p-3 text-white text-xs shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{bankAccount.code}. {bankAccount?.name}</label>)
                            }
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className='p-5 grid grid-cols-1 gap-4 justify-items-center'>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Select Date</span>
                            </label>
                            <input {...register("date")} type="date" defaultValue={date} placeholder="Date" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Bank Account Name</span>
                            </label>
                            <input  {...register("name")} type="text" value={!isSelectBankAccount ? name : currentBankAccount?.name || ''} placeholder="Type Invoice No Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Account No</span>
                            </label>
                            <input  {...register("accountno")} type="text" value={!isSelectBankAccount ? accountno : currentBankAccount?.accountno || ''} placeholder="Type Invoice No Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Invoice No</span>
                            </label>
                            <input {...register("invoice")} type="text" defaultValue={invoice} placeholder="Type Invoice No Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Amount</span>
                            </label>
                            <input {...register("amount")} type="number" defaultValue={amount} placeholder="Type Amount Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Purpose</span>
                            </label>
                            <input {...register("purpose")} type="text" defaultValue={purpose} placeholder="Type bankdeposit Purpose Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Status</span>
                            </label>
                            <select style={{ padding: '18px' }} {...register("status")} defaultValue={status} class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                                <option >Active</option>
                                <option>Inactive</option>
                            </select>
                        </div>
                        <input type='submit' value='Update Bank Deposit' className='btn bg-red-600 text-white' />
                    </form>
                </div>
            </div>
        </div >
    );
};

export default UpdateModal;