import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import sale from '../../../../src/assets/images/sale.png'
import useFetchData from '../../../hooks/useFetchData';

const TodaySales = () => {

    const date = new Date();
    const today = format(date, "yyyy-MM-dd");

    const { items: sales } = useFetchData("https://servers.expressmartbd.com/daudc/getcurrentmonthsales");

    const totalSales = sales?.filter(sale => sale?.day === today);


    //style={{width:'300px',height:'120px'}}
    return (
        <div class="mx-auto w-11/12 bg-coral text-black rounded-lg overflow-hidden bg-[#FDECEC] shadow-lg glassmorphism p-5">
            <div className='flex justify-between items-center'>
                <div class="flex flex-col justify-center items-center">
                    <div class="text-sm font-semibold mb-2">Today Sale</div>
                    <p class="text-xl text-black">৳ {totalSales?.length ? (totalSales[0]?.sales).toFixed(2) : 0.00}</p>
                </div>
                <div class="">
                    <img src={sale} alt="Card image" class="mx-auto my-auto h-20 w-20 rounded-full border-4 border-red-600 object-cover" />
                </div>
            </div>
        </div>


    );
};

export default TodaySales;