import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';

const useCurrentStockForPurchase = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [isSearching, setIsSearching] = useState(false);


    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value);
        setIsSearching(true)
    };

    useEffect(() => {
        if (!isSearching) {
            setSearchTerm('');
        }
    }, [isSearching]);


    const { data: products, isLoading } = useQuery(
        ['products', searchTerm], // Include the searchTerm in the query key
        () => {
            // Fetch products only if there's a search term; otherwise, return an empty array
            if (searchTerm) {
                return fetch(`https://servers.expressmartbd.com/daudc/getproductsforstock?searchTerm=${encodeURIComponent(searchTerm)}`).then(
                    (res) => res.json()
                );
            } else {
                return Promise.resolve([]); // Return an empty array when there's no search term
            }
        }
    );


    return { filtered: products, isLoading, searchTerm, setSearchTerm, handleSearchTermChange, isSearching, setIsSearching };
};

export default useCurrentStockForPurchase;
