import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import auth from '../../Firebase/Firebase';
import login from "../../assets/images/login.png"
import bg from '../../assets/images/bg.jpeg';
import bg2 from '../../assets/images/bg2.jpeg';
import bg3 from '../../assets/images/bg3.jpeg';
import bg4 from '../../assets/images/bg4.jpeg';
import bg6 from '../../assets/images/bg6.jpeg';
import bg8 from '../../assets/images/bg8.jpeg';
import bg9 from '../../assets/images/bg9.jpeg';
import bg10 from '../../assets/images/bg10.jpeg';
import BusinessInfo from '../Shared/BusinessInfo';
import axios from 'axios';

const Login = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [signInWithEmailAndPassword, user, loading, error,] = useSignInWithEmailAndPassword(auth);
    const [backgroundImageUrl, setBackgroundImageUrl] = useState('');

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    if (user) {
        navigate(from, { replace: true });
    }



    const onSubmit = async d => {
        const email = d.email;
        await signInWithEmailAndPassword(d.email, d.password);
        const { data } = await axios.post("https://servers.expressmartbd.com/daudc/login", { email })
        localStorage.setItem('accessToken', data.accessToken);
        // navigate(from, { replace: true });
    };

    useEffect(() => {
        const imageUrls = [bg, bg2, bg3, bg4, bg6, bg8, bg9, bg10];
        const randomIndex = Math.floor(Math.random() * imageUrls.length);
        const randomImageUrl = imageUrls[randomIndex];
        setBackgroundImageUrl(randomImageUrl);

    }, []);


    return (
        <div style={{ backgroundImage: `url(${backgroundImageUrl})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} className="flex flex-col justify-center items-center h-screen">

            <div className="relative p-8 rounded-lg shadow-md w-96 backdrop-filter backdrop-blur-lg bg-opacity-30">
                <div className="absolute  bg-cover bg-center filter blur-md opacity-60"></div>
                <div className="flex flex-col items-center justify-center mb-6">
                    <h1 className='text-red-600 text-xl mb-4 font-bold'>Welcome To {BusinessInfo.name}</h1>
                    <img src={login} alt="Logo" className="w-32 h-32 rounded-full border-4 border-white"
                    />
                </div>
                <form onClick={handleSubmit(onSubmit)}>
                    <div className="mb-4 bg-opacity-70 backdrop-filter backdrop-blur-lg rounded-md p-2">
                        <div className='flex justify-between'>
                            <label htmlFor="email" className="block text-gray-600 mb-2">Email</label>
                            <label class="label">
                                <span class="label-text-alt text-red-500">{errors.email && <span>This field is required</span>}</span>
                            </label>
                        </div>
                        <input {...register("email", { required: true, })} type="email" className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300 backdrop-blur-sm" />

                    </div>
                    <div className="mb-4 bg-opacity-70 backdrop-filter backdrop-blur-lg rounded-md p-2">
                        <div className='flex justify-between'>
                            <label htmlFor="password" className="block text-gray-600 mb-2"> Password</label>
                            <label class="label">
                                <span class="label-text-alt text-red-500">{errors.password && <span>This field is required</span>}</span>
                            </label>
                        </div>
                        <input {...register("password", { required: true })} type="password" className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300 backdrop-blur-sm" />

                    </div>
                    <div>
                        <button type="submit" className="w-full py-2 px-4 bg-red-600 font-bold text-white rounded-md hover:bg-red-700 focus:outline-none backdrop-blur-sm">Log in
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;


// src/App.js
// import React from 'react';

// const App = () => {
//   return (
//     <div>
//         <div className="2-96 shodow-xl mx-auto text-white bg-clip-padding backdrop-filter bg-white bg-opacity-10
//         backdrop-blur-md mt-20 py-10 px-8 rounded-md">
//             <div className="text-enter text-2xl">Login</div>
//             <form action="" className="mt-6">
//                 <div className="mb-4">

//                 </div>
//             </form>

//         </div>
//     </div>
//   );
// };

// export default App;




// const Login = () => {
//     const { register, handleSubmit, watch, formState: { errors } } = useForm();
//     const [signInWithEmailAndPassword, user, loading, error,] = useSignInWithEmailAndPassword(auth);

//     // const [token]= useWebToken(user);

//     const navigate = useNavigate();
//     const location = useLocation();
//     const from = location.state?.from?.pathname || "/";

//     if(user){
//         navigate(from, { replace: true });
//     }

//     const onSubmit = data => {
//         signInWithEmailAndPassword(data.email, data.password);
//     };
//     return (
//         <div>
//             <div className='flex justify-center items-center my-28'>
//                 <div className='w-9/12 lg:w-4/12 md:w-1/2 shadow-2xl p-12'>
//                 <form onClick={handleSubmit(onSubmit)}>
//                     <img className='w-2/4 mx-auto' src={login} alt="" />
//                     <div class="form-control">
//                         <label class="label">
//                             <span class="label-text">ইমেইল</span>
//                         </label>
//                         <input {...register("email", { required: true, })} type="email" class="input input-bordered" />
//                         <label class="label">
//                             <span class="label-text-alt text-red-500">{errors.email && <span>This field is required</span>}</span>
//                         </label>
//                     </div>
//                     <div class="form-control">
//                         <label class="label">
//                             <span class="label-text">পাসওয়ার্ড</span>
//                         </label>
//                         <input {...register("password", { required: true })} type="password" class="input input-bordered" />
//                         <label class="label">
//                             <span class="label-text-alt text-red-500">{errors.password && <span>This field is required</span>}</span>
//                         </label>
//                     </div>
//                     <button type='submit' className='btn btn-error w-full text-white font-bold'>লগইন</button>
//                 </form>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Login;

// src/App.js
// src/App.js